import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "forgot" */ '../views/ForgotView.vue')
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import(/* webpackChunkName: "about" */ '../views/ScheduleView.vue')
  },
  {
    path: '/reporter',
    name: 'reporter',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReporterView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  {
    path: '/group',
    name: 'group',
    component: () => import(/* webpackChunkName: "about" */ '../views/GroupView.vue')
  },
  {
    path: '/exhibi',
    name: 'exhibi',
    component: () => import(/* webpackChunkName: "about" */ '../views/ExhibiView.vue')
  },
  {
    path: '/hotal',
    name: 'hotal',
    component: () => import(/* webpackChunkName: "about" */ '../views/HotalView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/DetailView.vue')
  },
  {
    path: '/venue',
    name: 'venue',
    component: () => import(/* webpackChunkName: "about" */ '../views/VenueView.vue')
  },
  {
    path: '/down',
    name: 'down',
    component: () => import(/* webpackChunkName: "about" */ '../views/DownView.vue')
  },  
  {
    path: '/uc/info',
    name: 'uc/info',
    component: () => import(/* webpackChunkName: "about" */ '../views/uc/InfoView.vue')
  },
  {
    path: '/uc/pay',
    name: 'uc/pay',
    component: () => import(/* webpackChunkName: "about" */ '../views/uc/PayView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegView.vue')
  },
  {
    path: '/uc/password',
    name: 'uc/password',
    component: () => import(/* webpackChunkName: "about" */ '../views/uc/PasswordView.vue')
  }, 
  {
    path: '/uc/hotel',
    name: 'uc/hotel',
    component: () => import(/* webpackChunkName: "about" */ '../views/uc/HotelView.vue')
  },
  {
    path: '/uc/abstract',
    name: 'uc/abstract',
    component: () => import(/* webpackChunkName: "about" */ '../views/uc/AbstractView.vue')
  },
 
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 使用afterEach导航守卫
router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
