import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'aos/src/sass/aos.scss'
import 'swiper/scss'



window.addEventListener('resize', setSize)
setSize()
function setSize(){
  let size,
      winW = window.innerWidth;
  if (winW > 800) {
    size = Math.round(winW / 19.2)
  } else if (winW <= 800 && winW > 550) {
    size = 70
  } else if (winW <= 550) {
    size = Math.round(winW / 7.5)
  } else {
    size = 100
  }
  document.documentElement.style.fontSize = size + 'px'
}




Vue.config.productionTip = false
Vue.prototype.$http = axios

// 引入助手函数
import $U from './common/util.js';
Vue.prototype.$U = $U



Vue.prototype.baseDomainUrl = "https://iac2025.net888.top/api/"
Vue.prototype.baseDomainUcUrl = "https://iac2025.net888.top/uci/"
Vue.prototype.baseDomain = "https://iac2025.net888.top"


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
